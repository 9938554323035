<template>
    <div class="cms-menu">
        <Header
            :title="$t('Useful links')"
            :subtitle="$t('Questions? info@projectgezond.nl')"
            theme="white"
        >
                <template v-slot:button-start>
                    <button @click="$router.go(-1)" class="btn -icon-only">
                    <iconLeft />
                </button>
            </template>
        </Header>

        <div class="container">
            <button class="btn -gray -icon" @click="promptPWA" v-if="canInstallPwa">
                <icon-download />
                <span>{{ $t('Install Project Gezond as app') }}</span>
            </button>

            <ul class="head">
                <menu-item
                    v-for="page in pages"
                    :key="page.getId()"
                    :item="page"
                />
            </ul>
        </div>
    </div>
</template>

<script>
import PWA from "@/lib/PWA";
import MenuApi from "@/lib/api/Menu";
import Header from "@/components/Header";
import MenuItem from "@/components/MenuItem";

import iconDownload from "@/assets/icons/icon-download.svg?inline";
import iconLeft from "@/assets/icons/icon-arrow-left.svg?inline";

export default {
    components: {
        Header,
        iconDownload,
        iconLeft,
        MenuItem,
    },

    data() {
        return {
            pages: [],
            canInstallPwa: false
        };
    },
    methods: {
        promptPWA() {
            PWA.PromptInstall();
        }
    },
    async mounted() {
        this.pages = await MenuApi.GetListByPosition("footer");
        this.canInstallPwa = PWA.HasInstallAvailable();
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
