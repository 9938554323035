<template>
    <li v-if="item.isExternal()">
        <a :href="item.getExternalLink()">
            {{ item.getTitle() }}
        </a>
    </li>

    <router-link
        :to="item.getSlug()"
        v-slot="{ href, route, navigate }"
        v-else
        custom
    >
        <li :class="[(route.fullPath === $route.fullPath || $route.fullPath.includes(route.fullPath) && route.fullPath !== '/' ? 'active' : '')]">
            <a :href="href" @click="navigate">
                {{ item.getTitle() }}
            </a>
        </li>
    </router-link>
</template>

<script>
import MenuItem from "@/models/MenuItem";

export default {
    name: "MenuItem",

    props: {
        item: {
            type: MenuItem,
            required: true
        }
    }
};
</script>

<style lang="scss">
    @import "index";
</style>
