import HTTPClient from "@/lib/HTTPClient";
import MenuItem from "@/models/MenuItem";

const Menu = {};

Menu._menuBody = null;

/**
 * Gets all menu items from API
 *
 * @return  {Menu[]?}        Menu dataobject
 */
Menu.GetList = async function() {
    if (!Menu._menuBody) {
        Menu._menuBody = HTTPClient.Get("/v3/menus");
    }

    const response = await Menu._menuBody;
    const data = response.data ?? [];
    const status = response.status ?? 0;

    if (parseInt(status) !== 200) {
        Menu._menuBody = null;
        return [];
    }

    if (!data.data) {
        Menu._menuBody = null;
        return [];
    }

    Menu._cache = data.data
        .filter(i => i.is_visible)
        .map(i => new MenuItem(i));

    return Menu._cache;
};

Menu.GetListByPosition = async function(position) {
    let menu = await Menu.GetList();

    return menu.filter(i => i.getPosition() == position);
};

export default Menu;
