import DataObject from "./DataObject";
import PG from "@/lib/PG";

// ! PRO-469
export default class MenuItem extends DataObject {
    constructor(data) {
        super(data);
    }

    isExternal() {
        return this.getData("external_link") !== null;
    }

    // ! PRO-470
    getSlug() {
        if (this.getData("slug")) {
            return "/pagina/" + this.getData("slug");
        }

        let page = PG.ConvertIonicToRoute(this.getData("ionic_page_name"));

        if (page) {
            return page;
        }

        // TODO: Handle cases where ionic_page_name cant be found
        return "";
    }
}
